import ShareButtons from 'share-buttons/dist/share-buttons';
import $ from 'jquery';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import MenuInterface from '../classes/menu-interfaces';
import { clearInput } from './form';
import { createCookie, getCookie } from './helper';

// Fonction permettant de donner un attribut height au container de l'overlay
export function addHeightAttributeToContainer(classOverlay, nameEventOverlay, targetContainerOverlay) {
  const containerOverlay = document.querySelector(targetContainerOverlay);

  function onOpen() {
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  function onCloseComplete() {
    containerOverlay.style.height = null;
  }

  function onResize() {
    containerOverlay.style.height = null;
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onOpenOverlay${nameEventOverlay}`,
    function: onOpen,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onCloseCompleteOverlay${nameEventOverlay}`,
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: 'resize',
    function: onResize,
  });
  OBSERVER.on(`overlay${nameEventOverlay}`);
}

// Fonction gérant l'overlay de partage
export function overlayCookies() {
  if (document.querySelector('[data-overlay="cookies"]')) {
    if (getCookie('acceptCookies') !== 'true') {
      const html = document.querySelector('html');
      const overlay = document.querySelector('[data-overlay="cookies"]');
      const closeBtn = document.querySelector('.js-close-overlay-cookies');
      const acceptBtn = document.querySelector('.js-overlay-cookies-button-accept');
      const openDelay = 1000;
      const closeDuration = 800;

      // Fonction pour ouvrir l'overlay
      const open = () => {
        html.classList.add(`show-overlay-${overlay.dataset.overlay}`);
      };

      // Fonction pour fermer l'overlay
      const close = () => {
        html.classList.add(`closing-overlay-${overlay.dataset.overlay}`);

        setTimeout(() => {
          html.classList.remove(`show-overlay-${overlay.dataset.overlay}`, `closing-overlay-${overlay.dataset.overlay}`);
        }, closeDuration);
      };

      // Fonction pour accepter les cookies et créer le cookie 'acceptCookies' avec une expiration d'un an (365 jours)
      const acceptCookies = () => {
        createCookie('acceptCookies', 'true', 365);
      };

      // Ouvre l'overlay lors du chargement de la page avec un délai
      setTimeout(open, openDelay);

      // Event listener pour le bouton de fermeture et d'acceptation
      closeBtn.addEventListener('click', close);
      acceptBtn.addEventListener('click', acceptCookies);
    }
  }
}

// Fonction gérant l'overlay de partage
export function overlayShare() {
  if (document.querySelector('[data-overlay="share"]')) {
    const ovShare = new Overlay({
      name: 'share',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          open: '.js-open-overlay-share',
          close: '.js-close-overlay-share',
        },
      },
      options: {
        speed: 800,
      },
    });

    window.ovShare = ovShare;
    ovShare.init();

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer(ovShare, 'Share', '[data-overlay-container="share"]');

    // Message de confirmation de copie de l'URL ----------------
    const confirmClipboardCopy = () => {
      setTimeout(() => {
        // eslint-disable-next-line no-alert
        alert('L’adresse URL a bien été copiée dans votre presse-papier.');
      }, 300);
    };

    OBSERVER.add({
      name: 'confirmClipboardCopy',
      events: 'click',
      targets: '.js-share-copy',
      function: confirmClipboardCopy,
    });
    OBSERVER.on('confirmClipboardCopy');

    // Gestion de l'ouverture de l'overlay ----------------------
    const openShareOverlay = (e) => {
      if (e.currentTarget.dataset.rubric) {
        window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
      }
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'openShareOverlay',
      events: 'click',
      targets: '.js-open-overlay-share',
      function: openShareOverlay,
    });
    OBSERVER.on('openShareOverlay');

    // Gestion de la fermeture de l'overlay ---------------------
    const closeShareOverlay = () => {
      window.history.replaceState(null, null, ' ');
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'closeShareOverlay',
      events: 'click',
      targets: '.js-close-overlay-share',
      function: closeShareOverlay,
    });
    OBSERVER.on('closeShareOverlay');
  }
}

// Fonction permettant d'updapte l'overlay de partage
export function overlayShareUpdate() {
  window.ovShare.destroy();
  overlayShare();
}

// Fonction gérant l'overlay du popup
export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '.js-close-overlay-popup',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    ovPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer(ovPopup, 'Popup', '[data-overlay-container="popup"]');
  }
}

// Fonction gérant l'overlay du menu mobile
export function overlayMenuMobile() {
  if (document.querySelector('[data-overlay="menu-mobile"]')) {
    const ovMenuMobile = new Overlay({
      name: 'menu-mobile',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          toggle: '.js-toggle-overlay-menu-mobile',
          close: '.js-close-overlay-menu-mobile',
          switch: '.js-toggle-overlay-fast-links',
        },
      },
      options: {
        speed: 800,
        closeOnResize: true,
        goToSelector: 'html, body',
      },
    });

    ovMenuMobile.init();

    OBSERVER.add({
      name: 'overlayMenuMobile',
      events: 'onCloseCompleteOverlayMenu-mobile',
      function: MenuInterface.reset,
    });
    OBSERVER.add({
      name: 'overlayMenuMobile',
      events: 'onOpenOverlayMenu-mobile',
      function: MenuInterface.addAnimation,
    });
    OBSERVER.on('overlayMenuMobile');
  }
}

// Fonction gérant l'overlay du menu desktop
export function overlayMenuDesktop() {
  if (document.querySelector('[data-overlay="menu-desktop"]')) {
    const ovMenuDesktop = new Overlay({
      name: 'menu-desktop',
      click: {
        buttons: {
          trigger: '.js-trigger-overlay-menu-desktop',
          close: '.js-close-overlay-menu-desktop',
          switch: '.js-toggle-overlay-fast-links',
        },
      },
      animations: {
        selector: '.js-overlay-menu-desktop-sections',
        styles: [{
          property: 'height',
          value: 'dataset',
          easing: 'easeInOutQuart',
        }],
      },
      options: {
        speed: 800,
        closeOnResize: true,
        goToSelector: 'html, body',
      },
    });

    ovMenuDesktop.init();

    const onFocusInputOverlaySearch = () => {
      if (ovMenuDesktop.status === 'open') {
        ovMenuDesktop.close();
      }
    };

    OBSERVER.add({
      name: 'overlaySearch',
      events: 'focus',
      targets: '.js-overlay-search-input',
      function: onFocusInputOverlaySearch,
    });
  }
}

// Fonction gérant l'overlay des liens rapides
export function overlayFastLinks() {
  if (document.querySelector('[data-overlay="fast-links"]')) {
    const ovFastLinks = new Overlay({
      name: 'fast-links',
      click: {
        buttons: {
          toggle: '.js-toggle-overlay-fast-links',
          close: '.js-close-overlay-fast-links',
        },
      },
      animations: {
        selector: '.js-overlay-fast-links-container',
        styles: [
          {
            property: 'height',
            value: '100%',
            easing: 'easeInOutQuart',
          },
        ],
      },
      options: {
        speed: 800,
        closeOnResize: true,
        goToSelector: 'html, body',
      },
    });

    ovFastLinks.init();

    const onFocusInputOverlaySearch = () => {
      if (ovFastLinks.status === 'open') {
        ovFastLinks.close();
      }
    };

    OBSERVER.add({
      name: 'overlaySearch',
      events: 'focus',
      targets: '.js-overlay-search-input',
      function: onFocusInputOverlaySearch,
    });
  }
}

// Fonction gérant l'overlay de recherche
export function overlaySearch() {
  if (document.querySelector('[data-overlay="search"]')) {
    const ovSearch = new Overlay({
      name: 'search',
      events: {
        openComplete: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '.js-close-overlay-search',
        },
      },
      animations: {
        selector: '.js-overlay-search-sections',
        styles: [{
          property: 'height',
          value: 'dataset',
          easing: 'easeInOutQuart',
        }],
      },
      options: {
        speed: 800,
        closeOnResize: false,
        goToSelector: 'html, body',
      },
    });

    ovSearch.init();
    clearInput();

    const onOpenComplete = () => {
      const inputSearchMobile = document.querySelector('[data-overlay="search"] .js-header-search-search-field-input-mobile');
      const inputSearchDesktop = document.querySelector('[data-overlay="search"] .js-header-search-search-field-input-desktop');

      if (window.matchMedia('(max-width: 1000px)').matches) {
        inputSearchMobile.focus();
      } else {
        inputSearchDesktop.focus();
      }
    };

    const onCloseComplete = () => {
      const inputSearchMobile = document.querySelector('[data-overlay="search"] .js-header-search-search-field-input-mobile');
      const inputSearchDesktop = document.querySelector('[data-overlay="search"] .js-header-search-search-field-input-desktop');

      if (window.matchMedia('(max-width: 1000px)').matches) {
        inputSearchMobile.value = '';
      } else {
        inputSearchDesktop.value = '';
      }
    };

    let ajaxRequest = false;
    const changeSection = (e) => {
      const inputSearch = e;
      const html = document.querySelector('html');

      if (ajaxRequest) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort();
        if (html.dataset.section !== 'suggestions') {
          ovSearch.trigger('suggestions');
        }
      }

      if (inputSearch.target.value.length >= 3) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: e.currentTarget.value },
          update: { 'rechercheAutocomplete::autocomplete': '#autocomplete' },
          complete(data) {
            // Results loaded.
            ajaxRequest = false;

            // On affiche l'overlay des suggestions juste si ya des résultats
            if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
              if (html.dataset.section !== 'fast-results') {
                ovSearch.trigger('fast-results');
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (html.dataset.section !== 'suggestions') {
                ovSearch.trigger('suggestions');
              }
            }
          },
        });
      } else if (inputSearch.target.value.length < 3 && html.dataset.section !== 'suggestions') {
        document.querySelector('[data-overlay="search"] [data-section="suggestions"]').scrollTop = 0;
        ovSearch.trigger('suggestions');
      }
    };

    const onFocusInput = () => {
      const html = document.querySelector('html');

      if (html.dataset.section !== 'suggestions' && html.dataset.section !== 'fast-results') {
        document.querySelector('[data-overlay="search"] [data-section="suggestions"]').scrollTop = 0;
        ovSearch.trigger('suggestions');
      }
    };

    const onClearInput = () => {
      const html = document.querySelector('html');

      if (html.dataset.section !== 'suggestions') {
        document.querySelector('[data-overlay="search"] [data-section="suggestions"]').scrollTop = 0;
        ovSearch.trigger('suggestions');
      }
    };

    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onOpenCompleteOverlaySearch',
      function: onOpenComplete,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onCloseCompleteOverlaySearch',
      function: onCloseComplete,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'input',
      targets: '.js-header-search-search-field-input-mobile, .js-header-search-search-field-input-desktop',
      function: changeSection,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'focus',
      targets: '.js-overlay-search-input',
      function: onFocusInput,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'click',
      targets: '.js-overlay-search-clear-input',
      function: onClearInput,
    });
    OBSERVER.on('overlaySearch');
  }
}
