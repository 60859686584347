// Documentation: https://atomiks.github.io/tippyjs
import tippy from 'tippy.js';

export default function tippyCredits() {
  if (document.querySelector('[data-tippy="credits"]')) {
    window.tippyCredits = tippy('[data-tippy="credits"]', {
      theme: 'credits',
    });
  }
}
