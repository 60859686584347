// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';
import tippyCredits from './tooltip';
import { OBSERVER } from '../plugins';

// Swiper des alertes mobile
export function swiperAlertsMobile() {
  if (document.querySelector('[data-swiper="alerts-mobile"]')) {
    window.swiperAlertsMobile = new Swiper('[data-swiper="alerts-mobile"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="alerts-mobile"] .swiper-slide').length > 1),
      threshold: 10,
      speed: 700,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: true,
      on: {
        init(swiper) {
          if (window.matchMedia('(min-width: 601px)').matches) {
            swiper.autoplay.stop();
          } else {
            swiper.autoplay.start();
          }
        },
        resize(swiper) {
          if (window.matchMedia('(min-width: 601px)').matches) {
            swiper.autoplay.stop();
          } else {
            swiper.autoplay.start();
          }
        },
      },
    });

    const onClose = () => {
      document.documentElement.style.setProperty('--alerts-mobile-height', '0px');
      OBSERVER.off('swiperAlertsMobile');
    };

    OBSERVER.add({
      name: 'swiperAlertsMobile',
      events: 'click',
      function: onClose,
      targets: '.js-close-alerts-mobile',
    });
    OBSERVER.on('swiperAlertsMobile');
  } else {
    document.documentElement.style.setProperty('--alerts-mobile-height', '0px');
  }
}

// Swiper de la bannière dans la page d'accueil
export function swiperHomeBanner() {
  if (document.querySelector('[data-swiper="home-banner"]')) {
    window.swiperHomeBanner = new Swiper('[data-swiper="home-banner"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
      threshold: 10,
      speed: 700,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '[data-swiper-pagination="home-banner"]',
        type: 'bullets',
        clickable: true,
      },
      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
      },
    });

    tippyCredits();
  }
}

// Swiper des publications en vedette dans la page d'accueil
export function swiperHomePublications() {
  if (document.querySelector('[data-swiper="publications"]')) {
    window.swiperPublications = new Swiper('[data-swiper="publications"]', {
      slidesPerView: 1,
      loop: (document.querySelectorAll('[data-swiper="publications"] .swiper-slide').length > 2),
      spaceBetween: 21,
      threshold: 10,
      speed: 700,
      freeMode: false,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="publications"]',
        nextEl: '[data-swiper-next="publications"]',
        disabledClass: 'is-disabled',
      },
      breakpoints: {
        601: {
          slidesPerView: 2,
          spaceBetween: 23,
        },
        801: {
          slidesPerView: 2,
          spaceBetween: 26,
        },
        1001: {
          slidesPerView: 3,
          spaceBetween: 28,
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1301: {
          slidesPerView: 'auto',
          spaceBetween: 33,
        },
        1401: {
          slidesPerView: 'auto',
          spaceBetween: 35,
        },
      },
      on: {
        init(swiper) {
          if (window.matchMedia('(max-width: 600px)').matches) {
            swiper.slides.forEach((slide) => {
              if (slide.classList.contains('swiper-slide--button-see-all-publications') && !slide.classList.contains('swiper-slide-duplicate')) {
                const slideButton = slide;
                swiper.removeSlide(0);
                swiper.appendSlide(slideButton);
              }
            });
          }
        },
      },
    });
  }
}

// Swiper d'un filtre
export function swiperFilter() {
  if (document.querySelector('[data-swiper="filter"]')) {
    window.swiperFilter = new Swiper('[data-swiper="filter"]', {
      slidesPerView: 'auto',
      spaceBetween: 7,
      threshold: 10,
      speed: 300,
      freeMode: true,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="filter"]',
        nextEl: '[data-swiper-next="filter"]',
        disabledClass: 'is-disabled',
      },
    });
  }
}
