import { OBSERVER } from '../plugins';

export default class MenuInterface {
  static init() {
    if (document.querySelector('.js-menu-interfaces')) {
      MenuInterface.container = document.querySelector('.js-menu-interfaces');
      MenuInterface.interfaces = document.querySelectorAll('[data-menu-interface]');
      MenuInterface.interfacesLength = MenuInterface.interfaces.length;
      MenuInterface.activeInterfaceName = 'main';
      MenuInterface.activeInterface = document.querySelector(`[data-menu-interface="${MenuInterface.activeInterfaceName}"]`);
      MenuInterface.activeLevel = '1';

      MenuInterface.reset();
      MenuInterface.addAnimation();
      MenuInterface.setInterfaceHeight();

      OBSERVER.add({
        name: 'MenuInterface',
        events: 'click',
        targets: '[data-menu-button]',
        function: MenuInterface.changeInterface,
      });
      OBSERVER.add({
        name: 'MenuInterface',
        events: 'resize',
        function: MenuInterface.setInterfaceHeight,
      });
      OBSERVER.on('MenuInterface');
    }
  }

  static reset() {
    for (let i = 0; i < MenuInterface.interfacesLength; i += 1) {
      MenuInterface.interfaces[i].classList.remove('is-active', 'has-animation', 'is-on-the-left', 'is-on-the-right');
      MenuInterface.interfaces[i].scrollTop = 0;
      if (MenuInterface.interfaces[i].getAttribute('data-menu-interface-level') !== '1') {
        MenuInterface.interfaces[i].classList.add('is-on-the-right');
      }
    }
    MenuInterface.setActiveInterface('main');
    MenuInterface.setInterfaceHeight();
  }

  static addAnimation() {
    for (let i = 0; i < MenuInterface.interfacesLength; i += 1) {
      MenuInterface.interfaces[i].classList.add('has-animation');
    }
  }

  static setActiveInterface(interfaceName) {
    MenuInterface.activeInterfaceName = interfaceName;
    MenuInterface.activeInterface = document.querySelector(`[data-menu-interface="${MenuInterface.activeInterfaceName}"]`);
    MenuInterface.activeInterface.classList.add('is-active');
    MenuInterface.activeLevel = MenuInterface.activeInterface.getAttribute('data-menu-interface-level');
  }

  static changeInterface(e) {
    const INTERFACE_NAME = e.currentTarget.dataset.menuButton;
    MenuInterface.setActiveInterface(INTERFACE_NAME);

    for (let i = 0; i < MenuInterface.interfacesLength; i += 1) {
      MenuInterface.interfaces[i].classList.remove('is-on-the-right', 'is-on-the-left');

      if (MenuInterface.interfaces[i].getAttribute('data-menu-interface') !== MenuInterface.activeInterfaceName) {
        MenuInterface.interfaces[i].classList.remove('is-active');
        const LEVEL = MenuInterface.interfaces[i].getAttribute('data-menu-interface-level');
        if (LEVEL < MenuInterface.activeLevel) {
          MenuInterface.interfaces[i].classList.add('is-on-the-left');
        } else if (LEVEL > MenuInterface.activeLevel) {
          MenuInterface.interfaces[i].classList.add('is-on-the-right');
        }
      } else {
        MenuInterface.interfaces[i].scrollTop = 0;
      }
    }

    MenuInterface.setInterfaceHeight();
  }

  static setInterfaceHeight() {
    const HEIGHT = MenuInterface.activeInterface.offsetHeight;
    MenuInterface.container.style.height = `${HEIGHT}px`;
  }
}
