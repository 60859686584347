import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import { SCROLLFIRE, OBSERVER } from './plugins';
import fadeTransition from './transitions/fade-transition';
import {
  backendKeyBind, ogContent, zoomFontSize, calendar, adjustTopSectionAside, hideAlertsDesktop, formSearchByAddressAutocomplete, adresseSearchResultsLoaded,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import { resetDropzone, formsPackage, clearInput } from './functions/form';
import {
  overlayCookies, overlayShare, overlayPopup, overlayMenuMobile, overlayMenuDesktop, overlayFastLinks, overlaySearch,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formExample, formSuccess, formJobs, formContact, formNewsletter, formSearchByAddress,
} from './functions/validation';
import masksPackage from './functions/masks';
import {
  removeHoverOnMobile, hundredVH, fontSizeBasedOnCaracters,
} from './functions/helper';
import {
  swiperAlertsMobile, swiperHomeBanner, swiperHomePublications, swiperFilter,
} from './functions/sliders';
import tippyCredits from './functions/tooltip';
import MenuInterface from './classes/menu-interfaces';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';
/* global initFormBuilder */

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded;
window.dynamicPackage = dynamicPackage;

const initBarba = () => {
  barba.hooks.beforeEnter(async ({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    await Scroller.init();
    MenuInterface.init();
    overlayCookies();
    overlayShare();
    overlayPopup();
    overlayMenuMobile();
    overlayMenuDesktop();
    overlayFastLinks();
    overlaySearch();
    swiperAlertsMobile();
    hideAlertsDesktop();
    clearInput();
    zoomFontSize();
    fontSizeBasedOnCaracters();
    backendKeyBind();
    ShareButtons.update();
    SCROLLFIRE.init();
    removeHoverOnMobile();
    hundredVH();
    initFormBuilder();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => (el.classList && el.classList.contains('js-barba-prevent')) || (el.getAttribute('href') && el.getAttribute('href').indexOf('#') > -1),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formsPackage();
          masksPackage();
          tippyCredits();
          adjustTopSectionAside();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner();
          swiperHomePublications();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          swiperFilter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationView',
        beforeEnter() {
          dynamicPackage();
          tippyCredits();
          adjustTopSectionAside();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          swiperFilter();
          calendar();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventView',
        beforeEnter() {
          dynamicPackage();
          tippyCredits();
          adjustTopSectionAside();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          tippyCredits();
          adjustTopSectionAside();
          rubrics();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionsView',
        beforeEnter() {
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchByAddressView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formSearchByAddress();
          formSearchByAddressAutocomplete();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchByWordView',
        beforeEnter() {
          swiperFilter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formsPackage();
          masksPackage();
          formContact();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'styleView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          formsPackage();
          masksPackage();
          formExample();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
