import { OBSERVER } from '../plugins';

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      let k;
      const { styleSheets } = document;
      const styleSheetsLength = styleSheets.length;
      for (k = 0; k < styleSheetsLength; k += 1) {
        // for (const si in document.styleSheets) {
        const styleSheet = styleSheets[k];
        if (styleSheet.rules) {
          for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
            if (styleSheet.rules[ri].selectorText) {
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                const { selectorText } = styleSheet.rules[ri];
                const araySelectorText = selectorText.split(',');
                let newSelectorText = '';

                // Garder slm les selectorText no :hover
                for (let i = 0; i < araySelectorText.length; i += 1) {
                  if (!araySelectorText[i].match(':hover')) {
                    newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                  }
                }

                // Enlver la règle si la seule classe est un :hover
                if (newSelectorText === '') {
                  styleSheet.deleteRule(ri);
                } else {
                  styleSheet.rules[ri].selectorText = newSelectorText;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

// Permet de donner la hauteur exacte en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export function hundredVH() {
  function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize load',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('hundredVH');
}

// Réduit la taille des H1 s'il comporte trop de caractères
// Utilisation : data-font-size-based-on-caracters-count="{font-size}:min-max,{font-size}:min-max"
export function fontSizeBasedOnCaracters() {
  const elements = document.querySelectorAll('[data-font-size-based-on-caracters-count]');
  const elementsLength = elements.length;

  let i;
  let j;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const elementTextLength = element.textContent.length;
    const fontSizesDataAttributeValue = element.getAttribute('data-font-size-based-on-caracters-count');
    const fontSizes = fontSizesDataAttributeValue.split(',');
    const fontSizesLength = fontSizes.length;

    for (j = 0; j < fontSizesLength; j += 1) {
      const fontSizeAndCount = fontSizes[j];
      const fontSizeAndCountArray = fontSizeAndCount.split(':');

      const fontSize = fontSizeAndCountArray[0];

      const countMinAndMax = fontSizeAndCountArray[1];
      const countMinAndMaxArray = countMinAndMax.split('-');
      const min = countMinAndMaxArray[0];
      const max = countMinAndMaxArray[1];

      if (elementTextLength >= min && elementTextLength <= max) {
        element.classList.add(`u-font-size-${fontSize}`);
      }
    }
  }
}

// Permet de créer un cookie
export function createCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 86400000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

// Permet d'obtenir la valeur d'un cookie
export function getCookie(name) {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}
