import $ from 'jquery';
import anime from 'animejs/lib/anime.es';
import { Datepicker } from 'vanillajs-datepicker';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import { OBSERVER } from '../plugins';
import Accordions from '../classes/accordions';

// Permet d'ajouter un zéro au jour en delà de 10
export function addZeroToCalendarDay() {
  const addZero = () => {
    const dates = document.querySelectorAll('.js-calendar .day, .js-input-calendar .day');

    for (let i = 0; i < dates.length; i += 1) {
      const date = dates[i];
      const dateContent = date.innerHTML;
      const alreadyHaveZero = date.classList.contains('new-date');

      if (!alreadyHaveZero && dateContent < 10) {
        date.innerHTML = `0${dateContent}`;
        date.classList.add('new-date');
      }
    }
  };

  addZero();

  const targetNodes = document.querySelectorAll('.js-calendar .datepicker-grid, .js-input-calendar .datepicker-grid');
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true,
  };

  let observer;
  const callback = () => {
    addZero();
    observer.disconnect();
    targetNodes.forEach((targetNode) => {
      observer.observe(targetNode, config);
    });
  };

  observer = new MutationObserver(callback);
  targetNodes.forEach((targetNode) => {
    observer.observe(targetNode, config);
  });
}

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer-2"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer-2"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  // Ajouter un zéro au jour en delà de 10
  addZeroToCalendarDay();

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Permet d'ajouter les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

// Permet d'agrandir la taille de la police
export function zoomFontSize() {
  const html = document.querySelector('html');
  const buttonsOption = document.querySelectorAll('.js-zoom-font-size-option');
  let zoom = localStorage.getItem('zoom');

  // Initialisation par défaut de la font-size et de son animation
  if (!zoom) {
    zoom = '62.5';
    localStorage.setItem('zoom', zoom);
    html.style.fontSize = zoom;
  } else {
    html.style.fontSize = `${zoom}%`;
  }
  setTimeout(() => { html.style.transition = 'font-size 300ms'; }, 300);

  // Mettre le bouton qui correspond au zoom actif en mode actif
  buttonsOption.forEach((buttonOption) => {
    if (`${buttonOption.dataset.zoom}` === zoom) {
      buttonOption.classList.add('is-active');
    }
  });

  // Changer la font-size au clic des options
  const setZoom = (e) => {
    localStorage.setItem('zoom', e.target.dataset.zoom);
    buttonsOption.forEach((buttonOption) => {
      buttonOption.classList.remove('is-active');
    });
    e.target.classList.add('is-active');
    html.style.fontSize = `${e.target.dataset.zoom}%`;
  };

  OBSERVER.add({
    name: 'zoomFontSize',
    events: 'click',
    targets: '.js-zoom-font-size-option',
    function: setZoom,
  });
  OBSERVER.on('zoomFontSize');
}

// Permet de faire disparaître les alerts desktop un à la fois en les fermants
export function hideAlertsDesktop() {
  if (document.querySelectorAll('.js-alert-desktop').length) {
    const hideAlertDesktop = (e) => {
      anime({
        targets: e.currentTarget.closest('.js-alert-desktop'),
        height: '0px',
        duration: 500,
        easing: 'easeInOutBack',
      });
    };

    OBSERVER.add({
      name: 'hideAlertsDesktop',
      events: 'click',
      targets: '.js-close-alert-desktop',
      function: hideAlertDesktop,
    });
    OBSERVER.on('hideAlertsDesktop');
  }
}

// Permet d'ajuster le top de la section aside selon la hauteur du content dans le page title 3
export function adjustTopSectionAside() {
  if (document.querySelector('.js-page-title-secondary-with-image')) {
    const setTopSectionAside = () => {
      if (window.matchMedia('(min-width: 1201px)').matches) {
        const pageTitle3 = document.querySelector('.js-page-title-secondary-with-image');
        const pictureContainer = pageTitle3.querySelector('.js-page-title-secondary-with-image-picture-container');
        const sectionAside = document.querySelector('.js-section-aside');
        const topSectionAside = pageTitle3.clientHeight - pictureContainer.clientHeight;

        sectionAside.style.top = topSectionAside === 0 ? '0px' : `-${topSectionAside}px`;
      }
    };

    setTopSectionAside();
    OBSERVER.add({
      name: 'adjustTopSectionAside',
      events: 'resize',
      targets: 'window',
      function: setTopSectionAside,
    });
    OBSERVER.on('adjustTopSectionAside');
  }
}

// Fonction permettant de suggérer des valeurs pour les champs du formulaire de recherche par adresse
export function formSearchByAddressAutocomplete() {
  const targetFieldCivicNumberWrapper = '#form-search-by-address-wrapper--civic-number';
  const targetFieldStreetWrapper = '#form-search-by-address-wrapper--street';
  const targetFieldCivicNumber = '#form-search-by-address-input--civic-number';
  const targetFieldStreet = '#form-search-by-address-input--street';

  const numberAutocompleteOptions = {
    noCache: true,
    params: { 'civic-number': '' },
    serviceUrl: '/blanko/addrsearch/autocomplete/civic',
    appendTo: targetFieldCivicNumberWrapper,
  };

  $(targetFieldCivicNumber).autocomplete(numberAutocompleteOptions);

  $(targetFieldStreet).autocomplete({
    serviceUrl: '/blanko/addrsearch/autocomplete/street',
    appendTo: targetFieldStreetWrapper,
    onSelect() {
      numberAutocompleteOptions.params = { street: $(targetFieldStreet).val() };
      $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions);
    },
  });

  $(targetFieldStreet).keyup(() => {
    numberAutocompleteOptions.params = { street: $(targetFieldStreet).val() };
    $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions);
  });
}

export function adresseSearchResultsLoaded() {
  Scroller.scroll({
    destination: document.querySelector('#searchResultsWrapper'),
    easing: 'easeInOutQuart',
    duration: 1000,
    offset: -50,
  });
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.init();
}
