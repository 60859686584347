import $ from 'jquery';
import { resetDropzone } from './form';

// Fonction contenant tous les messages
function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
      charactersCount300: 'The text should not exceed 300 characters.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      charactersCount300: 'Le texte ne doit pas dépasser les 300 caractères.',
    };
  }

  return messages;
}

// Fonction exécutant la validation de chaque formulaire
export function formValidation(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.js-field').find('.js-form-error'));
    },
    success: (_, element) => {
      // Enlever la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.removeClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('error').addClass('valid');
      $(element).attr('aria-invalid', 'false');
    },
    highlight: (element) => {
      // Ajouter la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.addClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('valid').addClass('error');
      $(element).attr('aria-invalid', 'true');
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select').on('change', function selectChange() {
    $(this).valid();
  });
}

// Reset de validation
export function formSuccess(form, data) {
  const formElement = form;

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading');
    $('.stripe-loading-indicator').addClass('loaded');
    $(form)[0].reset();
    resetDropzone(form);
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha.reset();
    }

    // <div class="alert alert-success">x</div>

    let message = 'Le formulaire est bien envoyé !';
    const confirmationContainer = form.querySelector('.js-confirmation-message');

    if (confirmationContainer.innerHTML !== '') {
      message = confirmationContainer.innerHTML;
    } else if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
      message = 'The form has been sent !';
    }

    // Créer l'élément
    const confirmationNode = document.createElement('div');
    confirmationNode.className = 'alert alert-success';
    confirmationNode.textContent = message;

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode);

    // Cacher le formulaire
    formElement.style.display = 'none';

    // $.oc.flashMsg({text: message ,class: 'success'})
  }
}

// La validation des courriels
function validationEmail() {
  $.validator.addMethod('courriel', function methodEmail(value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du poid des fichiers
function validationFileSize() {
  $.validator.addMethod('filesize', function methodFileSize(value, element, param) {
    return this.optional(element) || (element.files[0].size <= param);
  }, 'La taille maximale du fichier doit être 5MB');
}

// La validation du formulaire d'exemple
export function formExample() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    'radioExampleInline[]': { required: true },
    'checkboxExample[]': { required: true },
  };
  const messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    'radioExampleInline[]': { required: m.required },
    'checkboxExample[]': { required: m.required },
  };

  formValidation('#form-example', '#form-example-submit', rules, messages);
}

// La validation du formulaire d'emplois
export function formJobs() {
  validationEmail();
  validationFileSize();

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, email: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { accept: 'pdf', filesize: 5242880, required: true },
    letter: { accept: 'pdf', filesize: 5242880 },
    'cookie-checkbox[]': { required: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, email: m.email, courriel: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { accept: m.accept, required: m.required },
    letter: { accept: m.accept },
    'cookie-checkbox[]': { required: m.required },
  };

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages);
}

// La validation du formulaire de contact
export function formContact() {
  validationEmail();

  const m = getMessages();

  const rules = {
    service: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, email: true, courriel: true },
    phone: { required: true, minlength: 12 },
    message: { required: true, maxlength: 300 },
    'cookie-checkbox[]': { required: true },
  };
  const messages = {
    service: { required: m.select },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, email: m.email, courriel: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required, maxlength: m.charactersCount300 },
    'cookie-checkbox[]': { required: m.required },
  };

  formValidation('#form-contact', '#form-contact-submit', rules, messages);
}

// La validation du formulaire de contact
export function formNewsletter() {
  validationEmail();

  const m = getMessages();

  const rules = {
    courriel: { required: true, email: true, courriel: true },
  };
  const messages = {
    courriel: { required: m.required, email: m.email, courriel: m.email },
  };

  formValidation('#formNewsletter', '#formNewsletter-submit', rules, messages);
}

// Validation de la recherche
export function formSearchByAddress() {
  const m = getMessages();

  const rules = {
    'civic-number': { required: true },
    street: { required: true },
  };
  const messages = {
    'civic-number': { required: m.required },
    street: { required: m.required },
  };

  formValidation('#form-search-by-address-page', '#submit-search-by-address-page', rules, messages);
}
